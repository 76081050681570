<template>
  <div>
    <div
      class="column-row column-row-3"
      style="margin-bottom: 80px"
    >
      <div class="column column-large first last">
        <div class="column-top">
          <h1>LEGO® Universe will be closed in 2012</h1>
        </div>
        <div class="column-content">
          <div style="display: block; margin: 0px 0px 20px; color: #888888">
            <!-- UniverseDictionary/images/1033/pic860C41157E04E9909E32D0B2BA7E19BE.png -->
            <img
              style="padding-top: 40px"
              src="@/assets/dictionary/sentinel-guard.png"
              align="right"
            /><br />Hello Adventurer, today we are very sad to announce that
            LEGO® Universe will be closing on January 30, 2012. This was a very
            difficult decision to make, but unfortunately LEGO Universe has not
            been able to attract the number of members needed to keep the game
            open.<br /><br />We realize how sad this will make the many players
            who have enjoyed LEGO Universe and we are committed to providing
            open communication with both kids and parents as we transition
            through this difficult time. We understand this is a challenging
            change and apologize for not being able to give parents forewarning
            before the general announcement.<br /><br />We are thankful to have
            had the opportunity to share this adventure with an amazing
            community of players. We hope you will continue to enjoy LEGO
            Universe for the last few months. As a thank you, if you are a
            paying subscriber on December 31, 2011, we will provide you the full
            game for the final month for free.<br /><br />Again, we want to
            thank the fantastic community of players who made LEGO Universe such
            a vibrant, fun and creative experience.<br /><br /><br /><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>